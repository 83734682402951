<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <h2 class="brand-text">パスワード再設定</h2>
        <h4 class="sub-brand-text">
          ご登録済みのメールアドレスを入力してください。パスワード再設定メールをお送りします。
        </h4>

        <!-- form -->
        <validation-observer ref="sendMailResetForm">
          <b-form class="auth-login-form mt-2" @submit.prevent="validatation">
            <!-- admin_login_id: required|email|max:128-->
            <validation-provider
              #default="{ errors }"
              name="メールアドレス"
              vid="email"
            >
              <b-form-group
                label-for="メールアドレス"
                label="メールアドレス"
                class="reset-email"
              >
                <b-form-input
                  id="id"
                  class="form-control-merge no-icon"
                  v-model="form.email"
                  :state="errors.length > 0 ? false : null"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- submit button -->
            <div class="btn-login">
              <b-button variant="primary" type="submit">
                再設定メールを送信
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required, email } from "@validations";
import { apiService } from "@/services/api.service";
import { STATUS } from "@/constant/constant";

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      form: {
        email: "",
      },
      required,
      email,
    };
  },
  methods: {
    validatation() {
      this.$refs.sendMailResetForm.validate().then((success) => {
        if (success) {
          this.onSendMailReset();
        }
      });
    },
    async onSendMailReset() {
      this.startLoading();
      try {
        const response = await apiService.post(
          "auth/password/forget",
          this.form
        );
        const status = response.data.code;
        this.endLoading();
        if (status === STATUS.SUCCESS) {
          this.$router.push({ name: "confirm-reset" });
          this.$store.dispatch("app/setMailSent", this.form.email);
        } else if (status === STATUS.BAD_REQUEST) {
          this.$refs.sendMailResetForm.setErrors(response.data.data);
        } else {
          this.$refs.sendMailResetForm.setErrors({
            email: [response.data.message],
          });
        }
      } catch (error) {
        this.endLoading();
        this.notifyError(error.data.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-auth.scss";
@import "@/assets/scss/style.scss";

.brand-text {
  font-family: Noto Sans;
  font-size: 25px;
  font-weight: 700;
  line-height: 44px;
}

.sub-brand-text {
  font-family: Noto Sans;
  font-size: 18px;
  font-weight: 500;
}

.btn-login {
  display: flex;
  justify-content: center;

  button {
    width: 200px;
  }
}

.reset-email {
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
